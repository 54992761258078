import $ from 'jquery'
import ClipboardJS from 'clipboard'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

import { openActionSocket } from '~/shared-vue-components/ActionForm/utils/websockets'

import '~/assets/sass/dashboard.scss'

window.jQuery = $
window.$ = $

// Attach openActionSocket function to window object
window.openActionSocket = openActionSocket

// Util functions
const handleSlugSectionVisibility = ($slugSection, $languageSelect, existingLanguages) => {
  // Display the slug field and a message if there is already an action in this campaign that is
  // using the selected language
  if (existingLanguages) {
    $slugSection.toggle(
      existingLanguages.indexOf($languageSelect.find('option:selected').val()) >= 0
    )
  }
}

// function setIframeHeightToContent(iframe) {
//   if (iframe) {
//     const iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
//     if (iframeWin.document.body) {
//       iframe.height = iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight;
//     }
//   }
// }

// Function to set height of campaign preview iframe content correctly
// function setCampaignPreviewIframeHeight() {
//   const iframe = document.getElementById('campaign-preview');
//   setIframeHeightToContent(iframe);
// }

// Function to min-height for .container-main
function setContainerMainHeight() {
  const $messagesHeight = $('.row.messages').height()
  const vdelta = $messagesHeight
    ? $(window).height() -
      $('footer.footer').height() -
      $('nav.navbar').height() +
      $messagesHeight -
      76
    : $(window).height() - $('footer.footer').height() - $('nav.navbar').height()
  if ($('body').hasClass('accounts')) {
    $('.container-main').css('min-height', `${vdelta - 60}px`)
  } else {
    $('.container-main').css('min-height', `${vdelta - 36}px`)
  }
}

function setDynamicHeights() {
  // setCampaignPreviewIframeHeight();
  setContainerMainHeight()
}

let resizeTimer = null

// Do these things every window resize event (debounced)
$(window).resize(function () {
  clearTimeout(resizeTimer)
  resizeTimer = this.setTimeout(setDynamicHeights, 50)
})

$(document).ready(() => {
  // Accordian Next/Prev button implementation
  const $collapses = $('.allout-accordion > .card > .collapse')

  $collapses.each((collapseIndex, collapseEl) => {
    const collapsesArray = $collapses.toArray()

    // If there is a # anchor in the url then toggle the collapseEl if its html id matches the
    // reference
    if (`#${collapseEl.id}` === window.location.hash) {
      $(collapseEl).collapse('toggle').focus()
    }

    const prevCollapseId = collapseIndex > 0 ? collapsesArray[collapseIndex - 1].id : null
    const nextCollapseId =
      collapseIndex < collapsesArray.length - 1 ? collapsesArray[collapseIndex + 1].id : null

    const $prevButton = $(collapseEl).find('.accordion-prev')
    const $nextButton = $(collapseEl).find('.accordion-next')

    if (prevCollapseId) {
      $prevButton.click(evt => {
        evt.preventDefault()
        $(`#${prevCollapseId}`).collapse('toggle')
      })
    } else {
      $prevButton.hide()
    }

    if (nextCollapseId) {
      $nextButton.click(evt => {
        evt.preventDefault()
        $(`#${nextCollapseId}`).collapse('toggle')
      })
    } else {
      $nextButton.hide()
    }
  })

  // Campaign instance form automations:
  $('.campaign-form').each(function () {
    const setCampaignFormFieldsVisibility = () => {
      $('#div_id_goal, #div_id_goal_offset, #div_id_fundraiser_goal_local').hide()

      const campaignType = $('#general select[name="type"]').val()

      if (campaignType.endsWith('fundraiser')) {
        $('#div_id_fundraiser_goal_local').show()
      } else {
        $('#div_id_goal, #div_id_goal_offset').show()
      }
    }

    setCampaignFormFieldsVisibility()

    $('.campaign-form #general select[name="type"]').on('change', function () {
      setCampaignFormFieldsVisibility()
    })
  })

  // Action dashboard form
  $('form.action-form').each((_, formEl) => {
    const $formEl = $(formEl)
    const $languageSelect = $formEl.find('.action-language select.selectpicker')
    const $slugSection = $formEl.find('.action-slug')
    const existingLanguages =
      $formEl.data('existingLanguages') && $formEl.data('existingLanguages').split(' ')

    $slugSection.toggle()

    // Initial slug section visibility check on page render
    handleSlugSectionVisibility($slugSection, $languageSelect, existingLanguages)

    $formEl.find('.action-language').change(() => {
      // If the language selector is changed, perform another slug section visibility check
      handleSlugSectionVisibility($slugSection, $languageSelect, existingLanguages)

      // If the language selected is PT, default the confirmation email from to Leandro
      if ($languageSelect.find('option:selected').val().startsWith('pt')) {
        $('#div_id_confirmation_email_from ul.dropdown-menu li[data-original-index=2] a').click()
      }
    })

    // Template fields
    $(
      '#div_id_confirmation_email_body_template, ' +
        '#div_id_deactivation_message_template, ' +
        '#div_id_auto_fill_form_copy, ' +
        '#div_id_form_submitted_heading, ' +
        '#div_id_sign_button_label'
    ).each(function () {
      const $templateField = $(this)
      $templateField.hide()

      // If the reveal template link is clicked, reveal the template field
      $(this)
        .siblings('a')
        .click(function (evt) {
          evt.preventDefault()
          $templateField.show()
          $(this).hide()
        })
    })
  })

  // Member dashboard
  // ----------------
  // Initialise any clipboard/copyable fields
  const copyable = document.querySelectorAll('.copyable')
  const clipboard = new ClipboardJS(copyable)
  clipboard.on('success', function (e) {
    // console.success('Copied!', e);
  })
  clipboard.on('error', function (e) {
    // console.error(e);
  })
  $('.copyable').click(function () {
    $(this).text($(this).data('done-title'))
  })

  // Initialise tooltips
  $('[data-toggle="tooltip"]').tooltip()

  // Campaign Image Store radio select control
  $('.bmd-cis-select input').each(function () {
    // Set state on page load
    if ($(this).is(':checked')) {
      $(this).parent().addClass('checked')
    }

    // Logic to set checked state on input
    $(this).change(function (evt) {
      // Set checked status on hidden input and label class
      $('label[for="' + this.id + '"]').toggleClass('checked')

      const checkedId = this.id
      // Apply checked to the correct input, and remove it for the others
      $('.bmd-cis-select input').attr('checked', function () {
        return this.id === checkedId
      })

      $(this)
        .parent()
        .parent()
        .parent()
        .children('.form-check')
        .find('label:not([for="' + this.id + '"])')
        .removeClass('checked')
    })
  })

  // Set dynamic heights on document ready
  setDynamicHeights()

  // Profile image upload widget
  $('#div_id_profile_image input[name=profile_image]').change(function () {
    $('.profile__image__overlay-text span').text(function () {
      return $(this).parent().data('input-changed-text')
    })
    $('.profile.profile-widget > .profile__image > img').hide()
    $('.profile-change-image').removeClass('hide')
    $('.profile-remove-image').hide()
  })
  $('.profile-change-image a').click(function (evt) {
    evt.preventDefault()
    $('.profile__image__overlay-text span').text(function () {
      return $(this).parent().data('input-text')
    })
    $('#div_id_profile_image input[name=profile_image]').val('')
    $('.profile.profile-widget > .profile__image > img').show()
    $('.profile-change-image').addClass('hide')
    $('.profile-remove-image').show()
  })

  // Campaign image upload widget changed handler
  $('.bmd-campaign-image-upload input[type=file]').change(function () {
    $('.bmd-campaign-image-upload label.btn-file span').text(function () {
      return $(this).parent().data('input-changed-text')
    })
    $('.bmd-campaign-image-upload .campaign-save-image').removeClass('hide')
    $('.bmd-campaign-image-upload .campaign-remove-image').addClass('hide')
    // Hide the companion selection control
    $(this)
      .closest('.bmd-campaign-image-upload-or-select')
      .each(function () {
        $(this).find('.bmd-cis-select').hide()
        $(this).find('p').hide()
      })
  })
  // Cancel save image click handler
  $('.bmd-campaign-image-upload .campaign-save-image a').click(function (evt) {
    evt.preventDefault()
    $('.bmd-campaign-image-upload .campaign-save-image').addClass('hide')
    const hasUploadedImage = $('.bmd-campaign-image-upload .thumbnail').get().length
    // Reveal, hide items based on presence uploaded image
    if (hasUploadedImage) {
      console.log('thumb')
      $('.bmd-campaign-image-upload .campaign-remove-image').removeClass('hide')
    } else {
      console.log('not thumb')
      $('.bmd-campaign-image-upload label.btn-file span').text(function () {
        return $(this).parent().data('input-text')
      })
      $(this)
        .closest('.bmd-campaign-image-upload-or-select')
        .each(function () {
          $(this).find('.bmd-cis-select').show()
          $(this).find('p').show()
        })
    }
  })
  // Remove image click handler
  $('.bmd-campaign-image-upload .campaign-remove-image').click(function () {
    $('.bmd-campaign-image-upload input[type=file]').val('')
  })
  $('.bmd-campaign-image-upload .thumbnail').each(function () {
    // if the thumbnail is present then we hide the other control
    $(this)
      .closest('.bmd-campaign-image-upload-or-select')
      .each(function () {
        $(this).find('.bmd-cis-select').hide()
        $(this).find('p').hide()
      })
  })

  // Fix dropdown toggle second-click issue
  $('.dropdown-toggle').dropdown()
  $('body').on('loaded.bs.select', function (e) {
    $('.dropdown-toggle').dropdown()
  })

  // Prepare links that open the modal to set the appropriate form action if needed
  $('a[data-form-action]').on('click', function () {
    const modalSelector = $(this).attr('href')
    // Ensure the correct action url is set on the modal form
    $(`${modalSelector} form`).attr('action', $(this).data('form-action'))
  })

  // Staff petition review page logic
  $('form.staff-review').each(function () {
    const $form = $(this)
    const $approveLink = $form.find('a.btn-action-approve')
    const $rejectLink = $form.find('a.btn-action-reject')
    const $actionButtons = $form.find('a.btn-action')
    const $cancelLink = $form.find('a.cancel')
    const $approvalCategorisationBlock = $form.find('#div_id_review_categorisation')
    const $approvalCategorisationBlockInput = $approvalCategorisationBlock.find('input')
    const $includeEmailsBlock = $form.find('#div_id_include_emails')
    const $includeEmailsBlockInput = $includeEmailsBlock.find('input')
    const $rejectionReasonBlock = $('#div_id_review_rejection_reason')
    const $rejectionReasonBlockInput = $rejectionReasonBlock.find('textarea')
    const $grantRegionBlock = $form.find('#div_id_grant_region')
    const $grantRegionBlockInput = $grantRegionBlock.find('input')
    const $reviewActionInput = $form.find('input[name=review_action]')
    const $submitButton = $form.find('button[type=submit]')
    const $infoText = $form.parent().find('span')

    $approveLink.click(function (evt) {
      evt.preventDefault()
      $cancelLink.toggleClass('hide')
      $reviewActionInput.val('approve')
      $actionButtons.toggleClass('hide')
      $approvalCategorisationBlock.toggleClass('hide')
      $approvalCategorisationBlock.find('.dropdown-toggle').focus()
      $approvalCategorisationBlockInput.attr('required', true)
      $includeEmailsBlock.toggleClass('hide')
      $includeEmailsBlockInput.attr('required', true)
      $grantRegionBlock.toggleClass('hide')
      $grantRegionBlockInput.attr('required', true)
      $submitButton.toggleClass('hide')
      $infoText.toggleClass('hide')
    })

    $rejectLink.click(function (evt) {
      evt.preventDefault()
      $cancelLink.toggleClass('hide')
      $reviewActionInput.val('reject')
      $cancelLink.data('prev-action', 'reject')
      $actionButtons.toggleClass('hide')
      $rejectionReasonBlock.toggleClass('hide')
      $rejectionReasonBlockInput.focus()
      $rejectionReasonBlockInput.attr('required', true)
      $submitButton.toggleClass('hide')
      $infoText.toggleClass('hide')
    })

    $cancelLink.click(function (evt) {
      evt.preventDefault()
      $(this).toggleClass('hide')
      $infoText.toggleClass('hide')
      $actionButtons.toggleClass('hide')

      if ($reviewActionInput.val() === 'reject') {
        $rejectionReasonBlock.toggleClass('hide')
      } else if ($reviewActionInput.val() === 'approve') {
        $approvalCategorisationBlock.toggleClass('hide')
        $includeEmailsBlock.toggleClass('hide')
        $grantRegionBlock.toggleClass('hide')
      }
      $submitButton.toggleClass('hide')
      $approvalCategorisationBlockInput.attr('required', false)
      $includeEmailsBlockInput.attr('required', false)
      $rejectionReasonBlockInput.attr('required', false)
      $grantRegionBlockInput.attr('required', false)
    })
  })

  // When a different language is selected, automatically submit the form
  const $form = $('form.lang-select')
  $form.find('select').change(function (evt) {
    const next = $form.find('option:selected').data('next')
    if (next) {
      $form.find('input[name=next]').val(next)
    }
    $form.submit()
  })

  // MGP goal override form
  $('#campaign-detail-goal').each(function () {
    const $form = $(this)
    const $goalInput = $form.find('input[name=goal]')
    const $saveButton = $form.find('button[name=save]')
    const $cancelButton = $form.find('button[name=cancel]')

    function toggleGoalPanels() {
      $('#manual-goal').toggleClass('hide')
      $('#managed-goal').toggleClass('hide')
      if (!$('#manual-goal').hasClass('hide')) {
        $goalInput.focus()
      }
    }

    $form.find('button[name=change]').click(function (evt) {
      toggleGoalPanels()
    })

    $saveButton.bind('mousedown touchstart', function (evt) {
      $(this).click()
    })

    $cancelButton.bind('mousedown touchstart', function (evt) {
      $(this).click()
    })

    $goalInput.focus(function (evt) {
      $(this).select()
      $saveButton.removeClass('hide')
      $cancelButton.removeClass('hide')
    })

    $goalInput.blur(function (evt) {
      $saveButton.addClass('hide')
      $cancelButton.addClass('hide')
    })

    $goalInput.each(function () {
      if ($(this).hasClass('error')) {
        $(this).focus()
        if ($('#manual-goal').hasClass('hide')) {
          toggleGoalPanels()
        }
      }
    })
  })

  // Click handler for dashboard list items
  $('.dash-list-item').on('click', function (e) {
    // Before doing anything, ensure the intended element was not a button or a link presented as a
    // button.
    // We don't want the click to propagate to this element in that case
    if (
      e.target.nodeName.toLowerCase() !== 'button' &&
      !(e.target.nodeName.toLowerCase() === 'a' && Array.from(e.target.classList).includes('btn'))
    ) {
      // If the detail url is in the data-href property navigate to that page
      if (this.dataset.href) {
        window.location = this.dataset.href
      }
    }
  })
})
