let AK_REST_ENDPOINT = 'https://roboticdogs.actionkit.com/rest/v1'

if (
  process.env.nodeEnv === 'production' ||
  process.env.NODE_ENV === 'production' ||
  process.env.VUE_APP_DEPLOYMENT === 'production'
) {
  AK_REST_ENDPOINT = 'https://allout.actionkit.com/rest/v1'
}

if (process.env.nodeEnv === 'staging' || process.env.VUE_APP_DEPLOYMENT === 'staging') {
  AK_REST_ENDPOINT = 'https://roboticdogs.actionkit.com/rest/v1'
}

let WEBSOCKETS_PROTOCOL = 'ws'
let HTTP_PROTOCOL = 'http'

if (
  process.env.NODE_ENV === 'production' ||
  process.env.VUE_APP_DEPLOYMENT === 'production' ||
  process.env.VUE_APP_DEPLOYMENT === 'staging'
) {
  WEBSOCKETS_PROTOCOL = 'wss'
  HTTP_PROTOCOL = 'https'
}

export default {
  AK_REST_ENDPOINT,
  WEBSOCKETS_PROTOCOL,
  HTTP_PROTOCOL
}
