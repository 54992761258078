import ReconnectingWebSocket from 'reconnecting-websocket'
import env from './env'

const generateWebSocketUrl = (actionId, petitionsHost, wsProtocol = null) => {
  const websocketHost = petitionsHost || window.location.host
  const websocketProtocol = wsProtocol || env.WEBSOCKETS_PROTOCOL
  return `${websocketProtocol}://${websocketHost}/ws/action/${actionId}/`
}

export const getWebSocketUrl = actionId =>
  generateWebSocketUrl(
    actionId,
    process.env.petitionsBaseDomain || process.env.PETITIONS_BASE_DOMAIN,
    process.env.wsProtocol
  )

export const openActionSocket = (actionId, petitionsHost, wsProtocol = null) => {
  // console.log('opening action socket for ', websocketHost, actionId);
  const actionSocket = new ReconnectingWebSocket(
    generateWebSocketUrl(actionId, petitionsHost, wsProtocol)
  )

  actionSocket.onmessage = function (e) {
    const { odometers } = window

    if (odometers) {
      const data = JSON.parse(e.data)
      // eslint-disable-next-line camelcase
      const { sig_inc } = data

      // eslint-disable-next-line camelcase
      if (sig_inc) {
        // Increment the value of the signature count odometers by 1
        window.odometers = window.odometers.map(({ od, count }) => {
          // Increment the value
          const newCount = count + 1
          // Update the odometer value
          od.update(newCount)
          // Return the same item with the count incremented
          return { od, count: newCount }
        })
      }
    }
  }

  actionSocket.onclose = function (e) {
    // eslint-disable-next-line no-console
    // console.error('Action socket closed');
  }

  return actionSocket
}

export const getActionSocket = actionId => {
  if (
    window.actionSocket &&
    [WebSocket.CLOSED, WebSocket.CLOSING].indexOf(window.actionSocket.readyState) < 0
  ) {
    return window.actionSocket
  }
  // Not attached to window yet? Create a new connection
  const actionSocket = openActionSocket(
    actionId,
    process.env.petitionsBaseDomain || process.env.PETITIONS_BASE_DOMAIN,
    process.env.wsProtocol
  )
  window.actionSocket = actionSocket
  return actionSocket
}
